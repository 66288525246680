@tailwind base;
@tailwind components;
@tailwind utilities;


/* body {
    font-family: Roboto, Arial, sans-serif;
  }
   */

   body {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  }
  

  .storiesViewerContainer {
    position: fixed; /* Или `absolute`, если нужно относительно родительского контейнера */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000; /* Убедитесь, что это значение достаточно высоко, чтобы быть поверх всего остального контента */
    background-color: rgba(0, 0, 0, 0.85); /* Полупрозрачный фон для улучшения восприятия */
  }
  
